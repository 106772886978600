<template>
  <div class="wallet-favorite">
    <div class="card mb-3">
      <div class="card-header">
        <h3 class="mb-0"><i class="el-icon-star-on"></i> Favorite</h3>
        <div class="position-absolute top-2 right-2 pt-1 pr-1">
          <el-button v-if="showall" type="text" @click="$router.push({path: '/wallet/favorite'})"> All Favorite </el-button>
        </div>
      </div>
      <div class="card-body min-vh" v-loading="loading">
        <div class="row">
          <div
            v-for="favorite of favoriteList"
            :key="favorite.uid"
            class="col-md-12 col-lg-6 col-xl-4"
          >
            <div class="card p-0 card-wallet">
              <div class="card-body mb-0 p-2">
                <div
                  class="
                    single_user_pil
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div 
                    class="d-inline-flex w-100 align-items-center"
                    @click="gotoPayment(favorite.uid)"
                    >
                    <div class="d-flex">
                      <div class="mt-0 mr-1">
                        <div class="text-xs text-center">
                          {{ favorite.from }}
                        </div>
                        <el-image
                          style="
                            width: 30px;
                            height: auto;
                            vertical-align: middle;
                          "
                          :src="`/img/currency-flags/${favorite.from.toLowerCase()}.png`"
                          fit="contain"
                        ></el-image>
                      </div>
                      <div class="mt-0">
                        <div class="text-xs text-center">{{ favorite.to }}</div>
                        <el-image
                          style="
                            width: 30px;
                            height: auto;
                            vertical-align: middle;
                          "
                          :src="`/img/currency-flags/${favorite.to.toLowerCase()}.png`"
                          fit="contain"
                        ></el-image>
                      </div>
                    </div>
                    <div class="w-100 text-center">
                      <span style="font-size: 12px">{{
                        favorite.favoriteName
                      }}</span>
                    </div>
                  </div>
                  <div class="action_btns d-flex">
                    <a href="#" class="action_btn" v-loading="loadingEdit && favoriteUID == favorite.uid" @click="editFavorite(favorite.uid)">
                      <i class="far fa-edit"></i>
                    </a>
                    <a href="#" class="action_btn" @click="deleteFavorite(favorite)">
                      <i class="fas fa-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteApi from "@/api/wallet/favorite";
export default {
  name: "FavoriteManage",
  props: {
    showall: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      loadingEdit: false,
      favoriteList: [],
      favoriteUID: "",
    };
  },
  methods: {
    getFavorite() {
      let query = this.limit == 0 ? "" : "?&order=DESC&limit="+this.limit;
      this.loading = true;
      FavoriteApi.getFavorite(query).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.favoriteList = data;
        }
      });
    },
    editFavorite(uid) {
      this.favoriteUID = uid;
      this.loadingEdit = true;
      FavoriteApi.getFavoriteByUid(uid).then(
        ({ result, data }) => {
          this.loadingEdit = false;
          if (result) {
            this.submitEditFavorite(uid, data.favoriteName);
          }
        },
        (err) => {
          this.loadingEdit = false;
          this.favoriteUID = "";
          if (err.response) {
            this.$swal("Message", err.response.data.message, "error");
          }
        }
      );
    },
    submitEditFavorite(uid, name) {
      this.$prompt("Enter favorite name", "Update Favorite", {
        confirmButtonText: "SAVE",
        cancelButtonText: "Cancel",
        inputValue: name,
        inputValidator: (input) => {
          return input ? true : false;
        },
        inputErrorMessage: "Please enter favorite name",
        beforeClose: (action, instance, done) => {

          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            let data = {
              type: "payment",
              favoriteName: instance.inputValue,
            };

            FavoriteApi.updateFavorite(uid, data).then(
              ({ result, message }) => {
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "SAVE";
                if (result) {
                  done();
                } else {
                  this.$swal("Message", message, "error");
                }
              }
            );
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "SAVE";
            done();
          }
        },
      })
        .then(({ value }) => {
          this.getFavorite();
          this.$message({
            type: "success",
            message: `Update favorite ${value} is success`,
          });
        })
        .catch((instance) => {
          instance.confirmButtonLoading = false;
          instance.confirmButtonText = "SAVE";
          this.$message({
            type: "info",
            message: "Update favorite canceled",
          });
        });
    },
    deleteFavorite(data) {
      this.$swal({
        title: "Delete Favorie",
        html: `This will delete <b>${data.favoriteName}</b>. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.submitDeleteFavorite(data);
        } else {
          this.$message(`Delete Canceled`);
        }
      });
    },
    submitDeleteFavorite(data) {
      FavoriteApi.deleteFavorite(data.uid).then(({ result, message }) => {
        if (result) {
          this.$swal(
            `Message`,
            `Delete <b>${data.favoriteName}</b> is success`,
            "success"
          ).then(() => {
            this.getFavorite();
          });
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    gotoPayment(uid){
      this.$router.push({path: `/wallet/favorite/${uid}/send`})
    }
  },
  mounted() {
    this.getFavorite();
  },
};
</script>

<style lang="scss">
.wallet-favorite {
  .action_btn {
    width: 25px;
    height: 25px;
    border-radius: 6px;
    background: 0 0;
    line-height: 25px;
    text-align: center;
    color: #e4e7ed;
    font-size: 12px;
    transition: 0.3s;
    display: inline-block;
    flex: 25px 0 0;

    &:hover {
      background: #67349d;
      color: #fff;
      box-shadow: 0 5px 10px rgba(136, 79, 251, 0.2);
    }
  }
}
</style>