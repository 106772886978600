<template>
  <div class="wallet-favorite">
    <div class="container-fluid mt--5">
      <favorite-manage />
    </div>
      
  </div>
</template>

<script>
import FavoriteManage from "@/views/Wallet/Favorite/components/FavoriteManage.vue";

export default {
  components: {
    FavoriteManage

  },

  mounted() {},
};
</script>

<style>
</style>