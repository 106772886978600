import request from '../request'

const Favorite = {
  addFavorite(data) {
    return request({
      url: "/v1/favorite",
      method: "post",
      data: data
    });
  },

  getFavorite(query = "") {
    return request({
      url: `/v1/favorite${query}`,
      method: "get"
    });
  },

  getFavoriteByUid(uid) {
    return request({
      url: `/v1/favorite/${uid}`,
      method: "get"
    });
  },

  updateFavorite(uid, data) {
    return request({
      url: `/v1/favorite/${uid}`,
      method: "put",
      data: data
    });
  },
  deleteFavorite(uid) {
    return request({
      url: `/v1/favorite/${uid}`,
      method: "delete"
    });
  },


}

export default Favorite